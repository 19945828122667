import axios from 'axios';

// let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api`;
// let apiUrl = 'https://save268.com/api';

// let apiUrl = 'http://localhost:3030/api';


let apiUrl = 'https://stagingapi.journey-finder.com/api';

// let apiUrl = 'http://54.194.208.178:3030/api';

// let apiUrl = 'http://54.194.208.178:3031/api';

// let apiUrl = 'https://prod.journey-finder.com/api';



const api = axios.create({
    baseURL: apiUrl,
    headers: {
      'Content-Type': 'application/json'
    }
});

export default api;